jQuery(function () {
  if ($(".js-single-gallery-slider").length > 0) {
    $(".js-single-gallery-slider").each(function () {
      const currentSlider = $(this);

      currentSlider.slick({
        slidesToShow: 1,
        dots: false,
        nextArrow: currentSlider
          .next()
          .find(".gallery-image-container__nextArrow"),
        prevArrow: currentSlider
          .next()
          .find(".gallery-image-container__prevArrow"),

        responsive: [
          {
            breakpoint: 768,
            settings: {
              dots: false,
              arrows: false,
              slidesToShow: 1,
              nextArrow: ".gallery-image-container__nextArrow",
            },
          },
        ],
      });
    });
  }
});
