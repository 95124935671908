jQuery(function () {
  const navi = $("#js-gallery-image-container__navigation");

  navi.slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    nextArrow: $(".gallery-image-container__nextArrow"),
    prevArrow: $(".gallery-image-container__prevArrow"),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  });
});
